
import 'url-search-params-polyfill';
(function(){
    if (!window.URLSearchParams){
        return;
    }
    if (!new URLSearchParams(window.location.search).get("bfconsole")){
        return;
    }

    panel = document.createElement("div");
    panel.id = "bfConsole";
    document.body.appendChild( panel);

    window.bfConsole = {
        panel: panel,
        log: function(m, isError) {
            var newline = document.createElement("div");
            newline.innerHTML = "&gt; " + m;
            if (isError) {newline.style.color="red"}
            this.panel.appendChild(newline);
        },
        error: function(m){
            this.log(m, true)
        }
    };
    ce = console.error;
    console.error = function(){
        bfConsole.error.apply(bfConsole, arguments)
        ce.apply(this, arguments)
    }
    cl = console.log;
    console.log = function(){
        bfConsole.log.apply(bfConsole, arguments)
        cl.apply(this, arguments)
    }


    window.addEventListener("error", function(event){
        bfConsole.log( event.type+": "+ event.message +" at "+ event.filename+" line "+event.lineno+":"+event.colno, true)
    } );
    anErrorrr;
})();	